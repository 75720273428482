<template>
  <div class="talent-details">
    <a-breadcrumb separator=">" class="a-bread-sty">
      <a-breadcrumb-item v-for="item in breadList" :key="item.name">
        <router-link :to="{ path: item.path === '' ? '' : item.path }">{{
          item.meta.title
        }}</router-link>
      </a-breadcrumb-item>
    </a-breadcrumb>
    <h2 align="center" class="to-h">KCA培训</h2>
    <p align="center" class="to-p">发布时间：2022-01-11</p>

    <div class="introduce">
      <p>
        本课程为您提供了一个独特的机会，使您能够在人大金仓的高级数据库管理上获得培训和认证。增加您的知识并提高您的技能熟练程度。了解如何更好地实现更高级的DBA职责，包括安全性、数据库调优和基准测试、监视、设置高可用性和复制等等。这个类涵盖了支持这些更深入的主题的概念和体系结构，以及可用于执行这些主题的选项。本文还介绍了如何更好地利用可用的工具来更有效地执行这些任务。
      </p>
      <img src="../../assets/image/32646.png" />
    </div>

    <div class="course-arrangement">
      <h3><img src="../../assets/image/32589.png" />课程安排</h3>
      <a-table
        class="a-table"
        :columns="columns"
        :data-source="courseList"
        :rowKey="demo"
        :pagination="false"
      >
        <template v-slot:content="content">
          <p v-if="content.contTitle">{{ content.contTitle }}</p>
          <div class="content-list">
            <p v-for="(item, index) in content.contList" :key="index">
              <img src="../../assets/image/835.png" />{{ item }}
            </p>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        { title: "日程", dataIndex: "schedule", width: "200px" },
        { title: "课程分类", dataIndex: "courseCategory", width: "200px" },
        {
          title: "主要内容",
          dataIndex: "content",
          scopedSlots: {
            customRender: "content",
          },
        },
      ],
      courseList: [
        {
          id: 1,
          schedule: "第一天上午",
          courseCategory: "KES基础功能专题",
          content: {
            contTitle:
              "DBA应该了解KES数据库的基础架构及其所具备的各项特性，本专题我们将分享如下内容：",
            contList: ["数据库基础知识", "安装卸载与启停", "KES体系结构详解"],
          },
        },
        {
          id: 2,
          schedule: "第一天下午",
          courseCategory: "KES基础功能专题",
          content: {
            contList: [
              "链接配置与客户端认证",
              "KSQL命令行工具",
              "DQL语句（表单查询与多表查询）",
            ],
          },
        },
        {
          id: 3,
          schedule: "第二天上午",
          courseCategory: "KES基础管理专题",
          content: {
            contTitle:
              "数据库日常管理是DBA最主要的职责之一，也是DBA消耗时间精力最多的工资。在本单元，我们将一句KES基础理论，介绍相关部分的最佳实践经验：",
            contList: [
              "内置多行函数与分组统计过滤",
              "库-模式-表空间相关的DDL语句",
              "管理表的基本DDL语句及字段的数据类型",
            ],
          },
        },
        {
          id: 4,
          schedule: "第二天下午",
          courseCategory: "KES基础管理专题",
          content: {
            contList: [
              "管理视图的基本DDL语句",
              "使用基本DML语句处理数据",
              "事物概述与TCL语句",
              "集合操作与集合运算符",
              "单行子查询和多行子查询",
              "内置单行函数-字符函数-数字函数-空值函数",
            ],
          },
        },
        {
          id: 5,
          schedule: "第三天上午",
          courseCategory: "KES安全专题",
          content: {
            contTitle:
              "数据库安全越来越受到用户的重视，如何保证数据的安全性，我们将分享如下知识和经验：",
            contList: [
              "DCL语句-系统权限与对象权限",
              "DCL语句-用户与角色",
              "DCL语句-用户与权限综合实验",
            ],
          },
        },
        {
          id: 6,
          schedule: "第三天下午",
          courseCategory: "KES监控与运维专题",
          content: {
            contTitle:
              "“工欲善其事，必先利其器”，对数据的运维更是如此。在本专题我们将分享如下知识：",
            contList: [
              "监控与运维方法论",
              "数据库对象管理器",
              "数据迁移工具",
              "控制台工具（含物理备份恢复）",
            ],
          },
        },
      ],

      name: "",
      breadList: [],
    };
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    demo(record) {
      // console.log(a);
      return record.id;
    },
    getBreadcrumb() {
      this.breadList = [];
      this.name = this.$route.name;
      // console.log(this.name);
      // console.log(this.$route.matched);
      // console.log(this.$route);
      this.$route.matched.forEach((ele, index, arr) => {
        this.breadList.push(ele);
      });
      // console.log(this.breadList);
    },
  },
};
</script>

<style lang="less">
.talent-details {
  .to-h {
    margin-top: 120px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }
  .to-p {
    margin-top: 30px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }
  .a-bread-sty {
    width: 1440px;
    margin: 40px auto;
    font-size: 20px;
  }
  .introduce {
    width: 1440px;
    margin: 0 auto;
    box-shadow: 0 10px 15px #cccccc;
    border-radius: 15px;
    background: linear-gradient(#f9fafe, #e7eeff);
    padding: 51px;
    margin-top: 51px;
    display: flex;
    align-items: center;
    p {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }
  }
  .course-arrangement {
    .a-table {
      tr {
        td {
          font-size: 24px;
        }
      }
    }
    width: 1440px;
    margin: 60px auto;
    h3 {
      margin-bottom: 30px;
    }
    p {
      // margin-bottom: 38px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }
    .content-list {
      // display: flex;
      // justify-content: space-around;
    }
  }
}
</style>
